import React from 'react'
import Hero from '../../components/Hero'
import Layout from '../../components/layout'
import Term from '../../components/Term'
import { Link } from 'gatsby'

const Terms = ({ data }) => (
    <Layout>
        <Hero
            large
            title='Terms of service'
            subTitle={`Updated: March 7, 2019`}
        />

        <div style={{ maxWidth: '800px', margin: '0px auto'}}>

          <p>
            By using Tueri, you agree to be bound to these terms of service.
          </p>

          <p>
            These terms of service ("Terms") between you and Tueri govern your access to, use of and participation in Tueri's website, products and services ("Services").
            If you are using the Services on behalf of an entity, you represent and warrant that you are authorized to accept these Terms on the entity's behalf and that
            the entity agrees to be responsible to Tueri if you violate these Terms.
          </p>

          <Term title='Purpose of the service'>
            Tueri offers a complete image management service that handles uploading, storage, processing and delivery of images to web browsers, apps and email clients.
          </Term>

          <Term title='1. Privacy'>
            Tueri is commited to proctecting your privacy. Please see Tueri's <Link to='/privacy'>Privacy Policy</Link> for information about how Tueri collects, uses
            and discloses information about users of the Service.
          </Term>

          <Term title='2. Availability'>
            Tueri strives to ensure that the Service is available 24 hours a day 7 days a week. However, there may be occasions when the Service may be intrrupted for upgrades,
            emergency repairs or due to failures of telecommunication links and equipment that are beyond our control. Every reasonable step will be taken by Tueri to minimize
            such distruption where it is within the reasonable control of Tueri. You agree that Tueri shall not be liable to you for any modification, suspension or discontinuance
            of the Service.
          </Term>

          <Term title='3. Material'>
            All brand and service names used in this Service that identify Tueri or third parties and their services are proprietary marks of Tueri and/or the relevant third parties.
            Nothing in this service shall be deemed to confer on any person any license or right on the part of Tueri or any third party with respect to such image, logo or name.
          </Term>

          <Term title='4. Intellectual Property'>
            Tueri reserves all rights not expressly granted in these Terms. Except for the license expressly stated in these Terms, you are not granted any rights
            in or to the Services. ALl rights in and to the Services not expressly granted in these Terms are hereby reserved and retained by Tueri.
            
            <h3>License</h3>
            On the condition that you comply with these Terms, Tueri grants you a non-exclusive, non-transferable, revocable license to access the Services
            (including any Tueri Material therin) soley for the purpose of enabling you to use the Service in the manner permitted by these Terms. If Tueri, in it's sole
            discretion, elects to provide any upgrade that replaces or supplements the original Services, this license will govern any such upgrade unless such upgrade
            is accompanied by a separate license, in which case the terms of that license will govern.

            <h3>Restrictions</h3>
            You are soley responsible for your conduct (and the conduct of anyone who uses the Services on your behalf) with respect to the Services, which you use at your
            own risk. Except as expressly permitted in writing by Tueri, you will not do, and will not permit any third party to do, any of the following:

            <ul>
              <li>for any unlawful purposes;</li>
              <li>to upload, post, email or otherwise transmit or communicate any material that is obscene, pornographic, unlawful, threatening, menacing,
                abusive, cruel, harmful, propaganda, an invasion of privacy or publicity rights, defamatory, libelous or illegal;</li>
              <li>to upload, post, email or otherwise transmit or communicate any material that you do not have a right to transmit or communicate under 
                any contractual or fiduciary relationship or which infringes any copyright, trademark, patent or other intellectual property right or any 
                moral right of any party including, but not limited to Tueri;</li>
              <li>to harm minors in any way, including, but not limited to, content that violates child pornography laws, child sexual exploitation laws
                and laws prohibiting the depiction of minors engaged in sexual conduct;</li>
              <li>to forge headers or otherwise manipulate identifiers in order to disguise the origin of any content transmitted through the Service;</li>
              <li>to upload, post, email or otherwise transmit any material which is likely to cause harm to Tueri or anyone else’s computer systems,
                including but not limited to that which contains any virus, code, worm, data or other files or programs designed to damage or allow
                unauthorized access to the Service which may cause any defect, error, malfunction or corruption to the Service.</li>
              <li>as part of any commercial communications campaign that has not been solicited by the recipients (i.e. spam).</li>
            </ul>
            <p>
              Violation of any of these agreements will result in the termination of your Tueri account. While Tueri prohibits such conduct and content on its site, 
              you understand and agree that Tueri cannot be responsible for the content posted on its website and you nonetheless may be exposed to such materials
              and that you use the Tueri service at your own risk.
            </p>
            <p>
              You futher agree that you shall not:
            </p>
            <ol>
              <li>interfere with or disrupt (or attempt to interfere with or disrupt) this Service or servers or networks connected to this Service,
                or to disobey any requirements, procedures, policies or regulations of networks connected to this Service; or</li>
              <li>provide any information to Tueri that is false or misleading, that attempts to hide your identity or that you do not have the right to disclose;</li>
              <li>make duplicate user accounts to bypass account restrictions.</li>
            </ol>
          </Term>

          <Term title='Disclaimer'>
            <p>
              Tueri agrees that all services will be carried out with reasonable skill and care.
            </p>
            <p>
              The service, Tueri’s sites and all related services are provided by Tueri “as is” with no warranties. All express, implied and statutory warranties,
              including, without limitation, the warranties of merchantability, fitness for a particular purpose, and non-infringement, are expressly disclaimed.
            </p>
            <p>
              You understand and agree that the submission of any digital content to Tueri, and the download or upload of any material through the service is 
              done at your own discretion and risk and that you will be solely responsible for any loss or damage to your content or any damage to your computer 
              system or loss of data that may result in the download or upload of any material. You are solely responsible for creating back-ups of your digital content.
            </p>
            <p>
              Except as expressly provided in the preceding paragraph, to the fullest extent allowed by law, Tueri shall not be liable for any direct, indirect, 
              incidental, consequential, special, exemplary, and punitive damages resulting from the use or provision of the service, Tueri’s site and all related 
              services, even if Tueri has been advised of the possibility of such damages. The foregoing disclaimers, waivers and limitations shall apply notwithstanding 
              any failure of essential purpose of any limited remedy.
            </p>
          </Term>

        </div>

    </Layout>
)

export default Terms